// @ts-nocheck
import { trefle } from "./index";
class network {
    static async fetch(link, options, callbacks) {
        var caching = (options === null || options === void 0 ? void 0 : options.caching) || false;
        var execTime = performance.now();
        const returneable = {
            fail: null,
            error: null,
            res: null
        };
        let request = new Request(link, options);
        try {
            switch (caching) {
                case true: {
                    var _callbacks$on, _callbacks$on2, _callbacks$on18, _callbacks$on18$proce;
                    trefle.wPrefix(trefle.console.prefixes.loading, `Fetching ${link} with cache usage...`, "");
                    if (callbacks !== null && callbacks !== void 0 && (_callbacks$on = callbacks.on) !== null && _callbacks$on !== void 0 && _callbacks$on.request)
                        callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on2 = callbacks.on) === null || _callbacks$on2 === void 0 ? void 0 : _callbacks$on2.request(request);
                    try {
                        var cache = await caches.open("trefle");
                    }
                    catch (e) {
                        var _callbacks$on3, _callbacks$on4;
                        if (callbacks !== null && callbacks !== void 0 && (_callbacks$on3 = callbacks.on) !== null && _callbacks$on3 !== void 0 && _callbacks$on3.fail)
                            callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on4 = callbacks.on) === null || _callbacks$on4 === void 0 ? void 0 : _callbacks$on4.fail(e);
                        trefle.strict.error(":", e);
                        returneable.fail = e;
                        return returneable;
                    }
                    if (await cache.match(request)) {
                        var _callbacks$on7, _callbacks$on8, _callbacks$on8$proces, _callbacks$on14, _callbacks$on15;
                        try {
                            var cachedRes = await cache.match(request);
                        }
                        catch (e) {
                            var _callbacks$on5, _callbacks$on6;
                            if (callbacks !== null && callbacks !== void 0 && (_callbacks$on5 = callbacks.on) !== null && _callbacks$on5 !== void 0 && _callbacks$on5.fail)
                                callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on6 = callbacks.on) === null || _callbacks$on6 === void 0 ? void 0 : _callbacks$on6.fail(e);
                            trefle.strict.error(":", e);
                            returneable.fail = e;
                            return returneable;
                        }
                        if (callbacks !== null && callbacks !== void 0 && (_callbacks$on7 = callbacks.on) !== null && _callbacks$on7 !== void 0 && _callbacks$on7.cacheFound)
                            callbacks.on.cacheFound(cachedRes);
                        trefle.wPrefix(trefle.console.prefixes.loading + "- Cached response ", `Found cached response for ${link}`, "black");
                        trefle.wPrefix(trefle.console.prefixes.loading + "- Cached response ", `Fetched ${link}`, "black");
                        if (callbacks !== null && callbacks !== void 0 && (_callbacks$on8 = callbacks.on) !== null && _callbacks$on8 !== void 0 && (_callbacks$on8$proces = _callbacks$on8.process) !== null && _callbacks$on8$proces !== void 0 && _callbacks$on8$proces.response) {
                            try {
                                var _callbacks$on9, _callbacks$on9$proces;
                                var result = await (callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on9 = callbacks.on) === null || _callbacks$on9 === void 0 ? void 0 : (_callbacks$on9$proces = _callbacks$on9.process) === null || _callbacks$on9$proces === void 0 ? void 0 : _callbacks$on9$proces.response(cachedRes));
                            }
                            catch (e) {
                                var _callbacks$on10, _callbacks$on11;
                                if (callbacks !== null && callbacks !== void 0 && (_callbacks$on10 = callbacks.on) !== null && _callbacks$on10 !== void 0 && _callbacks$on10.fail)
                                    callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on11 = callbacks.on) === null || _callbacks$on11 === void 0 ? void 0 : _callbacks$on11.fail(e);
                                trefle.strict.error(":", e);
                                returneable.fail = e;
                                return returneable;
                            }
                        }
                        if (result) {
                            var _callbacks$on12, _callbacks$on12$proce, _callbacks$on13, _callbacks$on13$proce;
                            trefle.wPrefix(trefle.console.prefixes.loading + "- Cached response ", `Processing cached response...`, "black");
                            if (callbacks !== null && callbacks !== void 0 && (_callbacks$on12 = callbacks.on) !== null && _callbacks$on12 !== void 0 && (_callbacks$on12$proce = _callbacks$on12.process) !== null && _callbacks$on12$proce !== void 0 && _callbacks$on12$proce.body)
                                var content = await (callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on13 = callbacks.on) === null || _callbacks$on13 === void 0 ? void 0 : (_callbacks$on13$proce = _callbacks$on13.process) === null || _callbacks$on13$proce === void 0 ? void 0 : _callbacks$on13$proce.body(result, cachedRes));
                            trefle.wPrefix(trefle.console.prefixes.success + "- Cached response ", `Fetched ${link} & processed cached response.`, "success");
                            trefle.wPrefix(" Cached response ", ":", "white", cachedRes);
                        }
                        trefle.wPrefix(trefle.console.prefixes.success + "- Cached response ", `Returned cached response & waiting for final response!`, "success");
                        trefle.loading(`Updating cached response of ${link}...`);
                        if (callbacks !== null && callbacks !== void 0 && (_callbacks$on14 = callbacks.on) !== null && _callbacks$on14 !== void 0 && _callbacks$on14.caching)
                            callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on15 = callbacks.on) === null || _callbacks$on15 === void 0 ? void 0 : _callbacks$on15.caching(cachedRes);
                    }
                    try {
                        var res = await fetch(request);
                        var resClone = res.clone();
                        var resClone2 = res.clone();
                        var resClone3 = res.clone();
                        var contentType = res.headers.get("content-type");
                        trefle.loading(`Fetched ${link}, output type: ${trefle.console.colors.Underscore}${contentType}${trefle.console.colors.Reset}`);
                    }
                    catch (e) {
                        var _callbacks$on16, _callbacks$on17;
                        if (callbacks !== null && callbacks !== void 0 && (_callbacks$on16 = callbacks.on) !== null && _callbacks$on16 !== void 0 && _callbacks$on16.fail)
                            callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on17 = callbacks.on) === null || _callbacks$on17 === void 0 ? void 0 : _callbacks$on17.fail(e);
                        trefle.strict.error(":", e);
                        returneable.fail = e;
                        return returneable;
                    }
                    if (callbacks !== null && callbacks !== void 0 && (_callbacks$on18 = callbacks.on) !== null && _callbacks$on18 !== void 0 && (_callbacks$on18$proce = _callbacks$on18.process) !== null && _callbacks$on18$proce !== void 0 && _callbacks$on18$proce.response) {
                        try {
                            var _callbacks$on19, _callbacks$on19$proce;
                            var result = await (callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on19 = callbacks.on) === null || _callbacks$on19 === void 0 ? void 0 : (_callbacks$on19$proce = _callbacks$on19.process) === null || _callbacks$on19$proce === void 0 ? void 0 : _callbacks$on19$proce.response(res));
                        }
                        catch (e) {
                            var _callbacks$on20, _callbacks$on21;
                            if (callbacks !== null && callbacks !== void 0 && (_callbacks$on20 = callbacks.on) !== null && _callbacks$on20 !== void 0 && _callbacks$on20.fail)
                                callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on21 = callbacks.on) === null || _callbacks$on21 === void 0 ? void 0 : _callbacks$on21.fail(e);
                            trefle.strict.error(":", e);
                            returneable.fail = e;
                            return returneable;
                        }
                    }
                    if (result) {
                        trefle.loading(`Processing response...`);
                        if (res.status < 400) {
                            var _callbacks$on22, _callbacks$on22$proce, _callbacks$on23, _callbacks$on23$proce, _callbacks$on24, _callbacks$on25, _callbacks$on26, _callbacks$on27;
                            if (callbacks !== null && callbacks !== void 0 && (_callbacks$on22 = callbacks.on) !== null && _callbacks$on22 !== void 0 && (_callbacks$on22$proce = _callbacks$on22.process) !== null && _callbacks$on22$proce !== void 0 && _callbacks$on22$proce.body)
                                var content = await (callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on23 = callbacks.on) === null || _callbacks$on23 === void 0 ? void 0 : (_callbacks$on23$proce = _callbacks$on23.process) === null || _callbacks$on23$proce === void 0 ? void 0 : _callbacks$on23$proce.body(result, res));
                            trefle.success(`Fetched ${link} & processed response.`);
                            trefle.console.cssLog({ whitePrefix: " Response ", content: ":" }, trefle.console.css, res);
                            cache.put(request, resClone);
                            trefle.success(`Updated cached response of ${link}.`);
                            trefle.success(`Fetched ${link}, processed response and updated in-cache response!`);
                            if (callbacks !== null && callbacks !== void 0 && (_callbacks$on24 = callbacks.on) !== null && _callbacks$on24 !== void 0 && _callbacks$on24.response)
                                callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on25 = callbacks.on) === null || _callbacks$on25 === void 0 ? void 0 : _callbacks$on25.response(resClone2);
                            if (callbacks !== null && callbacks !== void 0 && (_callbacks$on26 = callbacks.on) !== null && _callbacks$on26 !== void 0 && _callbacks$on26.cached)
                                callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on27 = callbacks.on) === null || _callbacks$on27 === void 0 ? void 0 : _callbacks$on27.cached(resClone);
                            returneable.res = resClone3;
                        }
                        else {
                            var _callbacks$on28, _callbacks$on29, _callbacks$on30, _callbacks$on31;
                            if (callbacks !== null && callbacks !== void 0 && (_callbacks$on28 = callbacks.on) !== null && _callbacks$on28 !== void 0 && _callbacks$on28.error)
                                var content = await (callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on29 = callbacks.on) === null || _callbacks$on29 === void 0 ? void 0 : _callbacks$on29.error(result, res));
                            trefle.warn(`Fetched ${link} & processed response, but an error code was returned. (Because of the error code, the response will not be cached)`);
                            returneable.error = true;
                            returneable.res = resClone3;
                            trefle.console.cssLog({ whitePrefix: " Response ", content: ":" }, trefle.console.css, res);
                            if (callbacks !== null && callbacks !== void 0 && (_callbacks$on30 = callbacks.on) !== null && _callbacks$on30 !== void 0 && _callbacks$on30.response)
                                callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on31 = callbacks.on) === null || _callbacks$on31 === void 0 ? void 0 : _callbacks$on31.response(resClone2);
                        }
                    }
                    else {
                        trefle.warn(`Fetched ${link} without result from callback, passing it as a return value!`);
                        if (res.status < 400) {
                            cache.put(request, res);
                            returneable.res = res;
                        }
                        else {
                            returneable.error = true;
                            returneable.res = res;
                        }
                    }
                    break;
                }
                case "cacheOnly": {
                    var _callbacks$on32, _callbacks$on33;
                    trefle.wPrefix(trefle.console.prefixes.loading, `Fetching ${link} from cache...`, "");
                    if (callbacks !== null && callbacks !== void 0 && (_callbacks$on32 = callbacks.on) !== null && _callbacks$on32 !== void 0 && _callbacks$on32.request)
                        callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on33 = callbacks.on) === null || _callbacks$on33 === void 0 ? void 0 : _callbacks$on33.request(request);
                    try {
                        var cache = await caches.open("trefle");
                    }
                    catch (e) {
                        var _callbacks$on34, _callbacks$on35;
                        if (callbacks !== null && callbacks !== void 0 && (_callbacks$on34 = callbacks.on) !== null && _callbacks$on34 !== void 0 && _callbacks$on34.fail)
                            callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on35 = callbacks.on) === null || _callbacks$on35 === void 0 ? void 0 : _callbacks$on35.fail(e);
                        trefle.strict.error(":", e);
                        returneable.fail = e;
                        return returneable;
                    }
                    if (await cache.match(request)) {
                        var _callbacks$on38, _callbacks$on38$proce;
                        try {
                            var cachedRes = await cache.match(request);
                        }
                        catch (e) {
                            var _callbacks$on36, _callbacks$on37;
                            if (callbacks !== null && callbacks !== void 0 && (_callbacks$on36 = callbacks.on) !== null && _callbacks$on36 !== void 0 && _callbacks$on36.fail)
                                callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on37 = callbacks.on) === null || _callbacks$on37 === void 0 ? void 0 : _callbacks$on37.fail(e);
                            trefle.strict.error(":", e);
                            returneable.fail = e;
                            return returneable;
                        }
                        if (callbacks.on.cacheFound)
                            callbacks.on.cacheFound(cachedRes);
                        trefle.wPrefix(trefle.console.prefixes.loading + "- Cached response ", `Found cached response for ${link}`, "black");
                        trefle.wPrefix(trefle.console.prefixes.loading + "- Cached response ", `Fetched ${link}`, "black");
                        if (callbacks !== null && callbacks !== void 0 && (_callbacks$on38 = callbacks.on) !== null && _callbacks$on38 !== void 0 && (_callbacks$on38$proce = _callbacks$on38.process) !== null && _callbacks$on38$proce !== void 0 && _callbacks$on38$proce.response) {
                            try {
                                var _callbacks$on39, _callbacks$on39$proce;
                                var result = await (callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on39 = callbacks.on) === null || _callbacks$on39 === void 0 ? void 0 : (_callbacks$on39$proce = _callbacks$on39.process) === null || _callbacks$on39$proce === void 0 ? void 0 : _callbacks$on39$proce.response(cachedRes));
                            }
                            catch (e) {
                                var _callbacks$on40, _callbacks$on41;
                                if (callbacks !== null && callbacks !== void 0 && (_callbacks$on40 = callbacks.on) !== null && _callbacks$on40 !== void 0 && _callbacks$on40.fail)
                                    callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on41 = callbacks.on) === null || _callbacks$on41 === void 0 ? void 0 : _callbacks$on41.fail(e);
                                trefle.strict.error(":", e);
                                returneable.fail = e;
                                return returneable;
                            }
                        }
                        if (result) {
                            var _callbacks$on42, _callbacks$on42$proce, _callbacks$on43, _callbacks$on43$proce;
                            trefle.wPrefix(trefle.console.prefixes.loading + "- Cached response ", `Processing cached response...`, "black");
                            if (callbacks !== null && callbacks !== void 0 && (_callbacks$on42 = callbacks.on) !== null && _callbacks$on42 !== void 0 && (_callbacks$on42$proce = _callbacks$on42.process) !== null && _callbacks$on42$proce !== void 0 && _callbacks$on42$proce.body)
                                var content = await (callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on43 = callbacks.on) === null || _callbacks$on43 === void 0 ? void 0 : (_callbacks$on43$proce = _callbacks$on43.process) === null || _callbacks$on43$proce === void 0 ? void 0 : _callbacks$on43$proce.body(result, cachedRes));
                            trefle.wPrefix(trefle.console.prefixes.success + "- Cached response ", `Fetched ${link} & processed cached response.`, "success");
                            returneable.res = cachedRes;
                            trefle.wPrefix(" Cached response ", ":", "white", cachedRes);
                        }
                        else {
                            trefle.warn(`Fetched ${link} without result from callback, passing it as a return value!`);
                            returneable.res = cachedRes;
                        }
                        trefle.wPrefix(trefle.console.prefixes.success + "- Cached response ", `Returned cached response!`, "success");
                    }
                    break;
                }
                default: {
                    var _callbacks$on44, _callbacks$on45, _callbacks$on48, _callbacks$on48$proce;
                    trefle.wPrefix(trefle.console.prefixes.loading, `Fetching ${link}...`, "");
                    if (callbacks !== null && callbacks !== void 0 && (_callbacks$on44 = callbacks.on) !== null && _callbacks$on44 !== void 0 && _callbacks$on44.request)
                        callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on45 = callbacks.on) === null || _callbacks$on45 === void 0 ? void 0 : _callbacks$on45.request(request);
                    try {
                        var res = await fetch(request);
                        var contentType = res.headers.get("content-type");
                        trefle.loading(`Fetched ${link}, output type: ${trefle.console.colors.Underscore}${contentType}${trefle.console.colors.Reset}`);
                    }
                    catch (e) {
                        var _callbacks$on46, _callbacks$on47;
                        if (callbacks !== null && callbacks !== void 0 && (_callbacks$on46 = callbacks.on) !== null && _callbacks$on46 !== void 0 && _callbacks$on46.fail)
                            callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on47 = callbacks.on) === null || _callbacks$on47 === void 0 ? void 0 : _callbacks$on47.fail(e);
                        trefle.strict.error(":", e);
                        returneable.fail = e;
                        return returneable;
                    }
                    returneable.res = res;
                    if (callbacks !== null && callbacks !== void 0 && (_callbacks$on48 = callbacks.on) !== null && _callbacks$on48 !== void 0 && (_callbacks$on48$proce = _callbacks$on48.process) !== null && _callbacks$on48$proce !== void 0 && _callbacks$on48$proce.response) {
                        try {
                            var _callbacks$on49, _callbacks$on49$proce;
                            var result = await (callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on49 = callbacks.on) === null || _callbacks$on49 === void 0 ? void 0 : (_callbacks$on49$proce = _callbacks$on49.process) === null || _callbacks$on49$proce === void 0 ? void 0 : _callbacks$on49$proce.response(res));
                        }
                        catch (e) {
                            var _callbacks$on50, _callbacks$on51;
                            if (callbacks !== null && callbacks !== void 0 && (_callbacks$on50 = callbacks.on) !== null && _callbacks$on50 !== void 0 && _callbacks$on50.fail)
                                callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on51 = callbacks.on) === null || _callbacks$on51 === void 0 ? void 0 : _callbacks$on51.fail(e);
                            trefle.strict.error(":", e);
                            return returneable;
                        }
                    }
                    if (result) {
                        trefle.loading(`Processing response...`);
                        if (res.status < 400) {
                            var _callbacks$on52, _callbacks$on52$proce, _callbacks$on53, _callbacks$on53$proce, _callbacks$on54, _callbacks$on55;
                            if (callbacks !== null && callbacks !== void 0 && (_callbacks$on52 = callbacks.on) !== null && _callbacks$on52 !== void 0 && (_callbacks$on52$proce = _callbacks$on52.process) !== null && _callbacks$on52$proce !== void 0 && _callbacks$on52$proce.body)
                                var content = await (callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on53 = callbacks.on) === null || _callbacks$on53 === void 0 ? void 0 : (_callbacks$on53$proce = _callbacks$on53.process) === null || _callbacks$on53$proce === void 0 ? void 0 : _callbacks$on53$proce.body(result, res));
                            trefle.success(`Fetched ${link} & processed response.`);
                            trefle.console.cssLog({ whitePrefix: " Response ", content: ":" }, trefle.console.css, res);
                            if (callbacks !== null && callbacks !== void 0 && (_callbacks$on54 = callbacks.on) !== null && _callbacks$on54 !== void 0 && _callbacks$on54.response)
                                callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on55 = callbacks.on) === null || _callbacks$on55 === void 0 ? void 0 : _callbacks$on55.response(res);
                            returneable.res = res;
                        }
                        else {
                            var _callbacks$on56, _callbacks$on57, _callbacks$on58, _callbacks$on59;
                            if (callbacks !== null && callbacks !== void 0 && (_callbacks$on56 = callbacks.on) !== null && _callbacks$on56 !== void 0 && _callbacks$on56.error)
                                var content = await (callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on57 = callbacks.on) === null || _callbacks$on57 === void 0 ? void 0 : _callbacks$on57.error(result, res));
                            trefle.warn(`Fetched ${link} and processed response, but an error code was returned.`);
                            trefle.console.cssLog({ whitePrefix: " Response ", content: ":" }, trefle.console.css, res);
                            if (callbacks !== null && callbacks !== void 0 && (_callbacks$on58 = callbacks.on) !== null && _callbacks$on58 !== void 0 && _callbacks$on58.response)
                                callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on59 = callbacks.on) === null || _callbacks$on59 === void 0 ? void 0 : _callbacks$on59.response(res);
                            returneable.error = true;
                            returneable.res = res;
                            return returneable;
                        }
                        return;
                    }
                    else {
                        trefle.warn(`Fetched ${link} without result from callback, passing it as a return value!`);
                        if (res.status < 400) {
                            returneable.res = res;
                        }
                        else {
                            returneable.error = true;
                            returneable.res = res;
                        }
                    }
                    break;
                }
            }
            trefle.log(`Fetch ${link} process time: ${parseInt(performance.now() - execTime)}ms (mode: Cache${caching ? caching === "cacheOnly" ? " Only" : " Enabled" : " Disabled"})`);
            return returneable;
        }
        finally {
            var _callbacks$on60, _callbacks$on61;
            if (callbacks !== null && callbacks !== void 0 && (_callbacks$on60 = callbacks.on) !== null && _callbacks$on60 !== void 0 && _callbacks$on60.finally)
                callbacks === null || callbacks === void 0 ? void 0 : (_callbacks$on61 = callbacks.on) === null || _callbacks$on61 === void 0 ? void 0 : _callbacks$on61.finally();
            caching = null;
            execTime = null;
            request = null;
        }
    }
}
export { network as default, network };
